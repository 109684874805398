.loaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
}

.loader {
  height: 6px;
  border-radius: 6px;
  background-color: rgba($color: #fff, $alpha: 0.2) !important;
}

.end {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 100px;
}

.layoutContainer {
  background: linear-gradient(
    192deg,
    var(--loading-g-one) 0%,
    var(--loading-g-two) 63.83%,
    var(--loading-g-three) 100%
  );
}

.layoutInnerContainer {
  align-items: center;
  justify-content: center;
}

.spinner {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  20% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  40% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
  }

  60% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  80% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
