.root {
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &[data-status='failed'] {
    background-color: var(--danger-transparent);
  }

  &[data-status='success'] {
    background-color: var(--success-transparent);
  }
}
