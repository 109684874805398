@import '@/styles/layout.scss';

.tipContainer {
  @extend .col;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 16px;
}

.tipItemContainer {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-left: -24px;
  padding-left: 24px;
  margin-right: -24px;
  padding-right: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.customActiveChip {
  border-radius: 20px;
  background-color: var(--iris-hover);
  color: var(--iris);
  width: fit-content;
}

.chip {
  transition: none;

  &:active {
    box-shadow: none;
  }
}
