.logo {
  width: 80px;
  height: 80px;
  border-radius: 16px;
  border: 1px solid var(--dim);
  margin: 0 auto;
  margin-top: -35px;
  background: var(--white);
  position: relative;
  overflow: hidden;

  > img {
    object-fit: cover;
  }
}

.nameContainer {
  padding-top: 20px;
  padding-bottom: 24px;
}
