.phoneInput {
  direction: ltr;

  > div {
    background: rgba(0, 0, 0, 0.04);
    html[dir='rtl'] & {
      right: 0px;
      left: unset;
    }
  }

  .input {
    width: 100%;
    padding: 8px 14px 8px 60px;
    border-color: #7d00d4;
    font-size: 16px;

    html[dir='rtl'] & {
      padding: 8px 60px 8px 36px;
      direction: ltr;
      text-align: right;
    }
  }

  > svg {
    html[dir='rtl'] & {
      left: 12px;
      right: unset;
    }
  }
}
