.wrapper {
  inset: 0;
  position: fixed;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  --gradient-height: 0px;
  --gradient-height-offset: -0px;

  &::-webkit-scrollbar {
    display: none;
  }

  &[data-gradient] {
    --gradient-height: 120px;
    --gradient-height-offset: -40px;
    &::before {
      width: 100%;
      content: '';
      height: calc(
        var(--gradient-height) + (var(--gradient-height-offset) * -1)
      );
      display: block;
      background: linear-gradient(
        180deg,
        rgba(6, 2, 1, 0.5) 0%,
        rgba(6, 2, 1, 0) 100%
      );
    }
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container {
  width: 100%;
  min-height: calc(100svh - var(--gradient-height));
  background-color: var(--white);
  &[data-width-capped] {
    max-width: var(--app-max-width);
  }
  &[data-rounded] {
    border-radius: 32px 32px 0px 0px;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-top: var(--gradient-height-offset);
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}
