.container {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paymentWrapper {
  margin-bottom: 16px;
}
