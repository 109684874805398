.container {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
}

.warning {
  background-color: rgba(255, 194, 28, 0.1);
}

.success {
  background-color: rgba(34, 169, 88, 0.1);
}
